<template>
  <div id="user_list">
    <el-card>
      <AdminTopOper> </AdminTopOper>
      
      <!-- 表格主体 -->
      <el-table :data="pageData.rows">
        <el-table-column prop="id" label="编号" width="80" />
        <!-- <el-table-column prop="id" label="编号" width="120" /> -->
        <el-table-column prop="contact" label="用户名" width="200">
          <template #default="scope">
            <div class="orderContactBox">
              <img class="orderContactPortrain" :src="scope.row.portrain" alt="" />
              {{ scope.row.username == '微信用户' ? scope.row.username + scope.row.id : scope.row.username }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="contact" label="联系人" width="400">
          <template #default="scope">
            {{ vid(scope.row.introduction) }}
          </template>

        </el-table-column>
       
        <!-- <el-table-column prop="contact" label="来源" width="150" v-if="scope.row.referer">
          <template #default="scope">
            {{ scope.row.referer.replace('https://servicewechat.com/', '').replace('/30/page-frame.html', '') }}
          </template>
        </el-table-column> -->
        <el-table-column prop="contact" label="来源" width="150">
          <template #default="scope">
            <!-- {{ scope.row.referer }} -->
            {{ refererName(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column prop="contact" label="上传文件" width="100">
          <template #default="scope">
            <el-tag type="success" v-if="scope.row.is_file==1">已上传</el-tag>
          </template>

        </el-table-column>

        <el-table-column prop="contact" label="来源" width="150">
          <template #default="scope">
            {{scope.row.create_ip}}
          </template>
        </el-table-column>



        <el-table-column prop="sort" label="排序" width="80" />
        <el-table-column prop="createdAt" label="创建时间" width="220" />
        <AdminTableButton> </AdminTableButton>
      </el-table>

      <AdminPagination> </AdminPagination>
    </el-card>

    <el-dialog v-model="pageData.dialogVisible" width="600px" class="adminDialog">
      <el-form ref="form" label-width="100px" size="default">
        <el-form-item label="ID:">
          <el-input v-model="pageData.row.id"></el-input>
        </el-form-item>

        <el-form-item label="折扣:">
          <el-input v-model="pageData.row.discount"></el-input>
        </el-form-item>
        <el-form-item label="邀请折扣:">
          <el-input v-model="pageData.row.inviteDiscount"></el-input>
        </el-form-item>

        <el-form-item label="公众号openid:">
          <el-input v-model="pageData.row.wx_openid_official"></el-input>
        </el-form-item>
        <el-form-item label="inviteId:">
          <el-input v-model="pageData.row.inviteId"></el-input>
        </el-form-item>

        <el-form-item label="introduction:">
          <el-input v-model="pageData.row.introduction"></el-input>
        </el-form-item>

        <el-form-item label="referer:">
          <el-input v-model="pageData.row.referer"></el-input>
        </el-form-item>
        <el-form-item label="inviteUserId:">
          <el-input v-model="pageData.row.inviteUserId"></el-input>
        </el-form-item>
        <el-form-item label="inviteName:">
          <el-input v-model="pageData.row.inviteName"></el-input>
        </el-form-item>
        <el-form-item label="moneyed:">
          <el-input v-model="pageData.row.moneyed"></el-input>
        </el-form-item>
        <el-form-item label="vip:">
          <el-input v-model="pageData.row.vip"></el-input>
        </el-form-item>

        <!-- <el-form-item label="邀请ID:">
          <el-input v-model="pageData.row.serviceTips"></el-input>
        </el-form-item> -->
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="itemAdd(0)">添加明细</el-button>

          <el-button @click="pageData.dialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="pageData.create()">Confirm</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
  
<script setup >
import { ref, onMounted, reactive } from 'vue'
import Api from '@/network'
import store from '../store'
const pageData = reactive(Api.adminPage('user'))
onMounted(pageData.getPage())


const vid = (introduction) => {
  return introduction
}

const refererName = (row) => {
  if(!row.referer) row.referer = ''
  for (let mini of store.state.initData.wxMiniProgram_list) {
    if (row.referer.indexOf(mini.appid)>0) {
      return mini.name
    }
  }

//  return introduction
}

</script>
  
  

<style lang="less">
.orderContactBox {
  display: flex;
  flex-direction: row;
  align-items: center;

  .orderContactPortrain {
    width: 30px;
    border-radius: 4px;
    margin-right: 6px;
  }
}
</style>
  